* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Oswald", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
section {
  margin-bottom: 3%;
}
.card {
  border: none !important;
  margin-bottom: 5% !important;
  margin-top: 5% !important;
  background-color: transparent !important;
}

span {
  color: aqua;
}

h4 {
  color: aqua !important;
}

.Hero-Section {
  color: white;
}
.Hero-Section h4,
span {
  font-size: xx-large;
}

.Hero-Left-Card {
  margin-top: 40%;
  color: white !important;
}
.Hero-Service-Section {
  text-align: center;
}
.Hero-Service-Section .card {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 2%;
}
.Hero-Service-Section .card img {
  height: 128px;
  width: 128px;
  margin: 0 auto;
}

.Why-chooseUs-Component .card {
  color: white;
  border-radius: 2%;
}

.Why-chooseUs-Component-Inner {
  padding: 5%;
}

.Services-Section-HomePage-SectionOne .card img {
  height: 128px;
  width: 128px;
  margin: 0 auto;
}

.Services-Section-SectionTwo .card img {
  height: 128px;
  width: 128px;
  margin: 0 auto;
}

.Projects-Component .card {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 2%;
}

.Work-Process-HomePage .card img {
  height: 128px;
  width: 128px;
  margin: 0 auto;
}

.Testimonials-Component .card img {
  height: 128px;
  width: 128px;
  margin: 0 auto;
}

.Testimonials-Component {
  background-image: url("../src/images/Opinion.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 2%;
}

.Clients-Logo-Component img {
  height: 128px;
  width: 128px;
  margin: 0 auto;
}

.FAQ-Component {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  border-radius: 2%;
}

.bgbg {
  position: relative;
  width: 100%;
  height: 120vh;
  background: #2f08dd;
  overflow: hidden;
}
.bgbg .air {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(https://1.bp.blogspot.com/-xQUc-TovqDk/XdxogmMqIRI/AAAAAAAACvI/AizpnE509UMGBcTiLJ58BC6iViPYGYQfQCLcBGAsYHQ/s1600/wave.png);
  background-size: 1000px 100px;
}
.bgbg .air.air1 {
  animation: wave 30s linear infinite;
  z-index: 1000;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;
}
.bgbg .air.air2 {
  animation: wave2 15s linear infinite;
  z-index: 999;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 10px;
}
.bgbg .air.air3 {
  animation: wave 30s linear infinite;
  z-index: 998;
  opacity: 0.2;
  animation-delay: -2s;
  bottom: 15px;
}
.bgbg .air.air4 {
  animation: wave2 5s linear infinite;
  z-index: 997;
  opacity: 0.7;
  animation-delay: -5s;
  bottom: 20px;
}
@keyframes wave {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 1000px;
  }
}
@keyframes wave2 {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: -1000px;
  }
}

.heartbeat {
  -webkit-animation: heartbeat 5s ease-in-out infinite both;
  animation: heartbeat 5s ease-in-out infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-3-28 15:1:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
